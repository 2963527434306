import React from "react";
import { graphql, PageRendererProps } from "gatsby";
import Img, { FluidObject } from "gatsby-image";

import { Layout } from "../components/Layout/Layout";
import { Hero } from "../components/Hero/Hero";

import styles from "./Photo.module.scss";

type PhotoTemplateProps = {
  data: {
    mdx: {
      frontmatter: {
        title: string;
        description: string;
        image: {
          childImageSharp: {
            fluid: FluidObject;
          };
        };
      };
    };
  };
  children: React.ReactNode;
} & PageRendererProps;

const PhotoTemplate = ({
  children,
  data: {
    mdx: { frontmatter },
  },
}: PhotoTemplateProps) => (
  <Layout>
    <Hero heading={frontmatter.title} lead={frontmatter.description}></Hero>
    <div className="container">
      <Img
        fluid={frontmatter.image.childImageSharp.fluid}
        className={styles.image}
        imgStyle={{ objectFit: "contain" }}
      />
      {children}
    </div>
  </Layout>
);

export default PhotoTemplate;

export const pageQuery = graphql`
  query($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      ...FullPhoto
    }
  }
`;
